<template>
  <form autocomplete="off" class="">
    <is-loading v-if="isLoading" :box="true" />
    <div class="row g-3 g-lg-4">
        <div v-if="!editMode" class="col-12">
            <div class="">
            <label>Select Lawyer</label>
            <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="name" label="name"
            :options="lawyers" 
            v-model="data.lawyer_id" placeholder="--select lawyer--"></multiselect>
            </div>
        </div> 
        <div class="col-12">
            <div class="">
            <label>Purpose of Assignment </label>
            <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="title" label="title"
            :options="caseDuties" 
            v-model="data.duty_id" placeholder="--select duty--"></multiselect>
            </div>
        </div>       
        <div class="col-12">
            <div class="d-flex mt-3 justify-content-end">
                <b-button variant="primary" 
                    @click.prevent="editMode? update() : confirmCreate()"
                     type="button">{{editMode? 'Update':'Assign Officer'}}</b-button>
            </div>
        </div>
    </div>
  </form>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Multiselect from '@vueform/multiselect'

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        IsLoading,
        Multiselect
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        },
        caseId:{
            type: String,
            required: true
        },
        assigned:{
            type:  Array,
            required: true
        }
    },
    data(){
        return {
            isLoading: true,
            data:{
                case_id: this.caseId,
                lawyer_id: null,
                duty_id: null,
                description: "" 
            }
        }
    },
    validations() {
        return {        
            data:{
                lawyer_id: {
                    required: helpers.withMessage("A lawyer is required", required),
                },  
                duty_id: {
                    required: helpers.withMessage("The purpose of assignment is required", required),
                },          
            }
        }
    },
    computed:{
        lawyers(){
            return this.$store.state.lawyer.list
            .map((i) => { 
                return  {
                    id: i.id, 
                    name: `${i.first_name} ${i.last_name}`,
                }
            });
        },
        caseDuties(){
            return this.$store.state.duty.list
        },
        selectedLawyer(){
            return this.lawyers.find(i => i.id == this.data.lawyer_id)
        }
    },
    methods:{
        confirmCreate(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                let text = `You are about to assign this case to <strong>${this.selectedLawyer.name}</strong>`;
                if(this.assigned.includes(this.data.lawyer_id)){
                    text = `You are about to re-assign this case to <strong>${this.selectedLawyer.name}</strong>`;
                }
                this.confirmAction({
                text: text,
                }).then((result) => {
                    if (result.value) { this.create(); }
                });
            })
        },
        create(){
            let formData = new FormData();
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue",true)
            this.$http.post('/case-lawyers/create', formData)
            .then(response => {
                this.$store.dispatch("changeLoaderValue",false)
                if(response.data.success){
                    this.$emit('addItem', response.data.data);
                    this.resetForm()
                }
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/case-lawyers/${this.editItem.id}/update`, formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updateItem', response.data.data);
                            this.resetForm()
                        }
                    })
            
            })
        },
        getLawyers(){
            this.isLoading = true;
            this.$store.dispatch('lawyer/fetchAllLawyers')
            this.$store.dispatch('duty/fetchAllItems')
            .then(response => this.isLoading = false );
        },
        resetForm(){
            this.v$.$reset()
            this.$emit('closeMe')
        }
    },
    mounted(){
        this.getLawyers()
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>

<style>

</style>