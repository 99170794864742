
<template>
  <div class="mb-5">
    <div class="d-flex mb-2 align-items-center justify-content-between">
      <h5>Case Lawyers</h5>
      <div>
        <button @click="popupModalShow = true" type="button" class="btn btn-sm btn-primary">Assign Lawyer</button>
      </div>
    </div>

    <div class="row gy-2">
      <div v-for="(item, itIndex) in items" :key="itIndex" class="col-md-6">
        <div v-if="item" class="card border-top overflow-hidden">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div v-if="item.lawyer" class="d-flex align-items-center">
                <div  class="cursor-pointer me-3">
                  <img class="avatar rounded-circle" :src="absoluteUrl(item.lawyer.image)" />
                </div>
                <div class="cursor-pointer">
                  <div class="text-dark fw-bold font-sm text-capitalize">
                    <span class="text-primary">
                      {{ item.lawyer.first_name}} {{ item.lawyer.last_name}}
                    </span>
                  </div>
                  <div><span class="text-dark font-sm">{{ item.lawyer.mobile }}</span></div>
                </div>
              </div>
              <div v-if="item.status == 'active'" class="dropdown dropstart no-arrow">
                <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <button @click.prevent="deactivateItem(item)" class="dropdown-item"
                      type="button"> Deactivate </button>
                  </li>
                  <li>
                    <button @click.prevent="initEdit(item)" class="dropdown-item"
                      type="button"> Update duty </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="pt-3">
              <div class="" >Status:  <span v-html="statusBadge(item.status)"></span></div>
              <div class="mt-2" v-if="item.duty">Assigned Duty: {{ item.duty.title }} </div>
              <div class="mt-2">Assigned At: {{$filters.date(item.assigned_at) }}</div>
              <div class="mt-2" v-if="item.deactivated_at">Deactivated At: {{$filters.date(item.deactivated_at) }} </div>
              <p v-if="item.creator" class="small mt-2 text-muted mb-0">
                Assigned by: {{item.creator.first_name}} {{item.creator.last_name}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="popupModalShow" hide-footer centered
      title="Assign a Law Officer" body-class="loading-viewport"
      title-class="fs-6" @hide="initializeAll()">
      <case-lawyer-form 
        v-if="popupModalShow"
        :caseId="$route.params.caseId"
        :assigned="items.map((i) => i.lawyer_id)"
        @addItem="addItem($event)"
        @updateItem="updateItem($event)"
        @closeMe="initializeAll()"
        :edit-mode="editMode" :edit-item="editItem"
      />
    </b-modal>
  </div>
</template>
<script>
import CaseLawyerForm from '@/components/forms/CaseLawyerForm.vue'

export default {
  name: "case-lawyers",
  components: {
    CaseLawyerForm,
  },
  props:{
    items:{
      type: Array,
      default:() => ([])
    }
  },
  data(){
    return {
      popupModalShow: false,
      editMode: false,
      editItem: null,
    }
  },
  methods: {
    addItem(item){
      this.$emit('addItem', item);
    },
    updateItem(item){
      this.$emit('updateItem', item);
    },
    initEdit(item){
      this.editMode = true;
      this.editItem = {...item}
      this.popupModalShow = true  
    },
    deactivateItem(item){
      this.confirmDelete({
        text: `You are about to deactivate <strong> ${item.lawyer.first_name} ${item.lawyer.last_name} </strong> in the case`,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/case-lawyers/${item.id}/deactivate`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.updateItem(response.data.data)
            }
          })
        }
      });
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
}

</script>

