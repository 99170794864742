
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="item" class="col-12">
      <h5 class="mb-3">Case Details</h5>
      <div class="row g-4">
        <div class="col-12">
          <div class="card mb-4 overflow-hidden">
            <div class="card-body">
              <div class="row">
                <div class="col-10 col-sm-8">
                  <div class="d-flex align-items-center justify-content-start mb-3">
                    <div class="">
                      <div v-if="item.status" class=""><strong>Status:</strong> <span v-html="statusBadge(item.status.status, item.status.color)"></span></div>
                    </div>
                  </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                  <div class="dropdown dropstart no-arrow">
                    <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                      role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ri-more-2-fill"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a @click="popupModalShow = true" class="dropdown-item"
                        href="javascript:void(0)" >
                          Update case </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-12"><h5 class="mb-1">{{item.parties}}</h5></div>
                    <div class="col-12"><strong>{{item.number_type}} Number:</strong> <span>{{item.case_number}}</span></div>
                    <div class="col-12"><strong>Category:</strong> <span v-if="item.category">{{item.category.title}}</span></div>
                    <div v-if="item.adjourned_date" class="col-md-6"><strong>Next Adjourned Date:</strong> <span>{{$filters.date(item.adjourned_date, 2) }}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-3">
            <case-lawyers :items="item.assignments" 
              @addItem="addAssignmentItem($event)"
              @updateItem="updateAssignmentItem($event)" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />

  <div v-if="item">
    <b-modal size="lg" v-model="popupModalShow" hide-footer centered
      title="Update case" body-class="loading-viewport"
      title-class="fs-6" @hide="initializeAll()">
      <case-form v-if="popupModalShow" @closeMe="initializeAll()"
      :editItem="item" :editMode="true" @updatedItem="updateItem($event)"/>
    </b-modal>
  </div>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import CaseForm from '@/components/forms/CaseForm.vue'
import CaseLawyers from './CaseLawyers.vue'

export default {
  name: "case-show",
  components:{
    IsLoading,
    CaseForm,
    CaseLawyers,
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      item: null
    }
  },
  watch: {
    '$route.params.caseId': function (id){
      if(id) this.fetchItem()
    },
  },
  computed:{
    parties(){
      return (type) => this.item?.parties.filter(i => i.type == type)
    }
  },
  methods: {
    updateItem(item){
      Object.assign(this.item, item)
    },
    addAssignmentItem(item){
      this.item.assignments.unshift(item);
    },
    updateAssignmentItem(item){
      const dataIndex = this.item.assignments.findIndex((i) => i.id == item.id)
      if (dataIndex > -1) {
        Object.assign(this.item.assignments[dataIndex], item)
      }
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/cases/${this.$route.params.caseId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      this.confirmDelete({
        text: "You want to delete case permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/cases/${this.item.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/cases'})
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

